<template>
  <div>
    <PromoBox
      v-if="activePromo"
      :visual="activePromo.display.visual"
      :link="activePromo.display.link"
      :linkLabel="activePromo.display.linkLabel"
    >
      <span v-html="activePromo.display.text"></span>
    </PromoBox>

    <div class="container">
      <InfoBox
        v-if="hasError"
        variant="danger"
      >
        Vozidlo sa nepodarilo nájsť. Skontrolujte EČV, alebo zadajte údaje ručne, prosím.
      </InfoBox>
      <InfoBox
        v-if="isUnsupportedVehicleType"
        variant="danger"
      >
        Online poisťujeme iba osobné a úžitkové vozidlá do 3,5 t na bežné použitie.
        Ak si želáte poistiť vozidlo iného typu, prosím, obráťte sa na naše kontaktné centrum – 02/38 11 11 16.
      </InfoBox>

      <InputLicensePlate
        v-model="vehicle.licensePlate"
        @submit="loadVehicleInfo()"
        @skip="tryProceed()"
      />
      <ModalWindow
        name="recaptcha"
        title="Potvrďte, prosím"
      >
        <VueRecaptcha
          sitekey="6LdiJYUbAAAAAB0SuwEJfjRIbkW9JtqFq1Ccftgx"
          @verify="recaptchaVerify"
        />
      </ModalWindow>
      <BaseSpinner
        v-if="isLoading"
        variant="primary"
        :size="32"
        center
      />
    </div>
  </div>
</template>

<script>
import GET_VEHICLE_INFO from '@/graphql/GetVehicleInfo.gql';
import VERIFY_CAPTCHA from '@/graphql/VerifyCaptcha.gql';

import VueRecaptcha from 'vue-recaptcha';

import BaseSpinner from '@gds/components/BaseSpinner';
import InputLicensePlate from '@gds/components/InputLicensePlate';
import InfoBox from '@gds/components/InfoBox';
import PromoBox from '@gds/components/PromoBox';
import ModalWindow from '@gds/components/ModalWindow';

import { mapGetters } from 'vuex';

export default {
  components: {
    VueRecaptcha,
    BaseSpinner,
    InputLicensePlate,
    InfoBox,
    PromoBox,
    ModalWindow,
  },
  data() {
    return {
      hasError: false,
      isUnsupportedVehicleType: false,
      isLoading: false,
      r3token: null, // reCAPTCHA v3 token
    };
  },
  computed: {
    ...mapGetters([
      'vehicle',
      'isPrefilledOfferFlow',
      'activePromo',
    ]),
  },
  methods: {
    async generateR3token() {
      await this.$recaptchaLoaded();
      this.r3token = await this.$recaptcha('vehicle');
    },
    recaptchaModalShow() {
      this.$modal.show('recaptcha');
    },
    async recaptchaVerify(token) {
      const result = await this.$apollo.mutate({
        mutation: VERIFY_CAPTCHA,
        variables: {
          token,
        },
      });
      if (result) {
        this.$modal.hide('recaptcha');
        this.loadVehicleInfo();
      }
    },
    async loadVehicleInfo() {
      await this.generateR3token();
      this.isLoading = true;
      this.hasError = false;
      this.isUnsupportedVehicleType = false;

      // TODO: Refactor this to properly use error hooks
      try {
        await this.generateR3token();
        const vehicleResult = await this.$apollo.query({
          query: GET_VEHICLE_INFO,
          variables: {
            licensePlate: this.vehicle.licensePlate,
            token: this.r3token,
          },
        });
        if (vehicleResult.data.skpData) {
          const { skpData } = vehicleResult.data;
          if (skpData.vehicleType === 'STANDARD') {
            const { vehicle } = this;
            vehicle.make = skpData.make;
            vehicle.model = skpData.model;
            vehicle.engineDisplacement = skpData.engineDisplacement;
            vehicle.enginePower = skpData.enginePower;
            vehicle.yearOfManufacture = skpData.yearOfManufacture;
            vehicle.weight = skpData.weight;
            vehicle.seatCount = skpData.seatCount;
            vehicle.registrationNumber = skpData.registrationNumber;
            vehicle.vin = skpData.vin;
            vehicle.color = skpData.color;
            vehicle.vehicleType = skpData.vehicleType;

            const isEmpty = (obj) => !obj || Object.keys(obj).length === 0;
            if (isEmpty(vehicle.makeMap)) {
              vehicle.makeMap = { [skpData.make]: skpData.makeText };
            }
            if (isEmpty(vehicle.modelMap)) {
              vehicle.modelMap = { [skpData.model]: skpData.modelText };
            }

            if (this.$route.meta.step === 0) {
              this.tryProceed();
            }
          } else {
            this.isUnsupportedVehicleType = true;
          }
        }
        this.isLoading = false;
      } catch (e) {
        console.log(e.message);
        if (e.message === 'GraphQL error: too many requests') {
          this.recaptchaModalShow();
        } else {
          this.hasError = true;
        }
        this.isLoading = false;
      }
    },
    tryProceed() {
      if (this.$v.validator.check(this)) {
        this.$store.state.progress.stepReached = 1;
        if (this.isPrefilledOfferFlow) {
          this.$router.push('/autorizacia');
        } else {
          this.$router.push('/1');
        }
      }
    },
  },
};
</script>
