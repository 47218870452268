<template>
  <div class="promo-box">
    <img
      v-if="visual"
      :src="visual"
      class="visual"
      alt=""
    >
    <div class="body" v-if="!!$slots.default || link">
      <BaseParagraph>
        <slot/>
        <BaseLink v-if="link" :href="link" target="_blank">
          {{ linkLabel }}
        </BaseLink>
      </BaseParagraph>
    </div>
  </div>
</template>

<script>
import './PromoBox.scss';
import BaseParagraph from '@gds/components/BaseParagraph';
import BaseLink from '@gds/components/BaseLink';

export default {
  name: 'PromoBox',
  components: {
    BaseParagraph,
    BaseLink,
  },
  props: {
    visual: {
      type: String,
    },
    link: {
      type: String,
    },
    linkLabel: {
      type: String,
      default: 'Zobraziť štatút',
    },
  },
};
</script>
