<template>
  <div class="input-license-plate">
    <BaseParagraph class="intro">
      Zadajte EČV/ŠPZ a údaje o aute vyplníme za vás
      <TooltipSet buttonSize="small">
        Online poisťujeme len osobné a úžitkové autá do 3,5 tony.
      </TooltipSet>
    </BaseParagraph>
    <div class="car-reader">
      <div class="license-plate">
        <div class="badge">
          <img src="./img/lp-badge.svg" alt="SK" class="badgeimage">
        </div>
        <div class="inputwrap">
          <input
            type="text"
            class="input"
            v-model="valueMirror"
            @click="showPlaceholder = false"
          >
        </div>
        <div class="placeholder" v-show="showPlaceholder">
          Zadajte EČV
        </div>
      </div>
    </div>
    <BaseParagraph>
      <BaseLink @click.prevent="$emit('skip')">Pokračovať bez zadania EČV</BaseLink>
      <BaseButton
        v-if="showContinueButton"
        size="small"
        @click="$emit('submit')"
        :style="{ marginLeft: '8px' }"
      >
        Pokračovať
      </BaseButton>
    </BaseParagraph>
  </div>
</template>

<script>
import './InputLicensePlate.scss';
import BaseParagraph from '@gds/components/BaseParagraph';
import BaseLink from '@gds/components/BaseLink';
import BaseButton from '@gds/components/BaseButton';
import TooltipSet from '@gds/components/TooltipSet';

const requiredLength = 7;

export default {
  name: 'InputLicensePlate',
  components: {
    BaseParagraph,
    BaseLink,
    BaseButton,
    TooltipSet,
  },
  props: {
    value: {
      type: String,
    },
    showContinueButton: {
      type: Boolean,
    },
  },
  data() {
    return {
      showPlaceholder: !this.value,
      valueMirror: this.value,
    };
  },
  methods: {
    togglePlaceholder(newValue) {
      this.showPlaceholder = (newValue === '');
    },
    emitValue() {
      const cleanValue = this.valueMirror.replace(/\W/g, '');
      this.$emit('input', cleanValue);
      if (cleanValue.length === requiredLength) {
        this.$emit('submit');
      }
    },
  },
  watch: {
    valueMirror: {
      handler(newValue) {
        if (newValue === newValue.toUpperCase()) {
          this.togglePlaceholder(newValue);
          this.emitValue();
        } else {
          this.valueMirror = this.valueMirror.toUpperCase();
        }
      },
    },
  },
};
</script>
